import { useEffect, useState, useRef, Fragment } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Dialog, Transition } from '@headlessui/react';
import AppConfig from "../AppConfig";
import Loader from "./Loader";

const Keys  = () => {
    const {keycloak} = useKeycloak();
    const [updated, setUpdated] = useState(Date.now());
    const [keys, setKeys] = useState([]);
    const [keyName, setKeyName] = useState('');
    const [keyId, setKeyId] = useState('');
    const [keyPlan, setKeyPlan] = useState('2x');
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const saveButtonRef = useRef(null);
    const [saveButtonLabel, setSaveButtonlabel] = useState('Create');
    const [err, setErr] = useState('');
    const [loadingAfterClick, setLoadingAfterClick] = useState(false);
    const [isDeleting, setDeleting] = useState('');
    const [isRegenerating, setRegenerating] = useState('');
    const [isLoading, setLoading] = useState(true);
    const [isEditing, setEditing] = useState(true);
    const [copyKey, setCopyKey] = useState({
        keyState: false,
        keyId: '',
    });
    const plans = AppConfig.plans;
    //console.log(AppConfig.plans['2x']);

    useEffect(() => {
        setLoading(true);
        fetch(AppConfig.api_base_url + 'v1/keys',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                },
            })
            .then((response) => response.json())
            .then((data) => {
                setKeys(data.data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
            })
    }, [updated, keycloak.token]);


    // Create First API Key
    const createFirstKey = async () => {
        if (keycloak.isTokenExpired()) {
            keycloak.updateToken();
        }
        const data = new FormData();
        data.append('name', 'key-one');
        data.append('plan', '1x');
        setLoading(true);
        try {
            const response = await fetch(AppConfig.api_base_url + 'v1/keys', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                },
                body: data
            });

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }

            // const result = await response.json();
            setUpdated(Date.now());
            createCustomer();
            setLoading(false);
            alert('Your free API Key has been created. Please note that it may take upto 3 minutes for the key to be available globally');
        } catch (err) {
            setErr(err.message);
        } finally {

        }

    };

    const createCustomer = async() => {
        if (keycloak.isTokenExpired()) {
            keycloak.updateToken();
        }
        try {
            const response = await fetch(AppConfig.api_base_url + 'v1/customers', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                }
            });

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }

        } catch (err) {
            setErr(err.message);
        } finally {

        }

    }

    // Create another API Key
    const createKey = async (event) => {
        event.preventDefault();
        if (keycloak.isTokenExpired()) {
            keycloak.updateToken();
        }
        setLoadingAfterClick(true);
        event.currentTarget.disabled = true;
        if (keyName === '') {
            alert('Please enter a name for your API Client.');
            return false;
        }
        if (keyPlan === '' || keyPlan === '1x') {
            alert('Please select a valid plan.');
            return false;
        }
        const data = new FormData();
        data.append('name', keyName);
        data.append('plan', keyPlan);
        try {
            const response = await fetch(AppConfig.api_base_url + 'v1/keys', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                },
                body: data
            });

            const json = await response.json();

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }

            setLoadingAfterClick(false);
            setOpen(false);
            window.location = json.data.stripeUrl;
            setUpdated(Date.now());
        } catch (err) {
            setErr(err.message);
        } finally {

        }
    };

    // Update API Key
    const updateKey = async (event, keyId, keyName) => {
        if (keycloak.isTokenExpired()) {
            keycloak.updateToken();
        }
        event.preventDefault();
        setLoadingAfterClick(true);
        event.currentTarget.disabled = true;
        if (keyName === '') {
            alert('Please enter a name for your API Client.');
        }
        try {
            const response = await fetch(AppConfig.api_base_url + 'v1/keys/' + keyId, {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({name: keyName})
            });

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }

            setLoadingAfterClick(false);
            setUpdated(Date.now());
            setOpen(false);

        } catch (err) {
            setErr(err.message);
        } finally {

        }
    };

    const openEditForm = (keyId, keyName, keyPlan) => {
        setEditing(true);
        setKeyId(keyId);
        setKeyName(keyName);
        setKeyPlan(keyPlan);
        setSaveButtonlabel('Update');
        setOpen(true);
    }

    const OpenCreateForm = () => {
        setEditing(false);
        setLoadingAfterClick(false)
        setKeyName('');
        setKeyPlan('2x');
        setSaveButtonlabel('Create');
        setOpen(true);
    }

    const deleteKey = async (keyId, keyName, keyPlan) => {
        if (keycloak.isTokenExpired()) {
            keycloak.updateToken();
        }
        if (keyPlan === '1x') {
            alert('Sorry, you cannot delete the free API Client.');
            return false;
        }
        let answer = window.confirm("Are you sure you want to delete the client " + keyName + '? Please note that this cannot be undone.');
        if (answer === true) {
            try {
                setDeleting(keyId);
                const response = await fetch(AppConfig.api_base_url + 'v1/keys/' + keyId, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                    }
                });

                if (!response.ok) {
                    throw new Error(`Error! status: ${response.status}`);
                }
                setUpdated(Date.now());
                setDeleting('');
            } catch (err) {
                setErr(err.message);
            } finally {

            }

        }
    }

    const getSubscriptionBillingURL = (subscriptionId) => {
        fetch(AppConfig.api_base_url + 'v1/stripe/billing',
            {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                },
            })
            .then((response) => response.json())
            .then((data) => {
                window.location = data.data + '/subscriptions/' + subscriptionId + '/update';
            })
            .catch((err) => {
                console.log(err.message);
            });
    }

    const regenerateKey = async (keyId) => {
        if (keycloak.isTokenExpired()) {
            keycloak.updateToken();
        }
        try {
            setRegenerating(keyId);
            const response = await fetch(AppConfig.api_base_url + 'v1/keys/' + keyId, {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Bearer ' + keycloak.token, // notice the Bearer before your token
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({key: 'regenerate'})
            });

            if (!response.ok) {
                throw new Error(`Error! status: ${response.status}`);
            }
            alert('Your API Key has been regenerated. It may take up to 3 minutes for the new key to be available globally.');
            setUpdated(Date.now());
            setRegenerating('');

        } catch (err) {
            setErr(err.message);
        } finally {

        }

    }

    const copyApiKey = async(apiId, apiKey) => {
        await navigator.clipboard.writeText(apiKey);
        setCopyKey({
            keyState: true,
            keyId: apiId
        });

        await new Promise(resolve => setTimeout(resolve, 3000));
        setCopyKey({
            keyState: false,
            keyId: ''
        });
    }

    if (isLoading) {
        return (
            <>
                <Loader />
            </>
                )
    } else {
        if (keys.length === 0) {
            return (
                <div
                    className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-24 lg:px-8">
                    <h2 className="text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
                        <span className="block">Looks like you're new here.</span>
                        <span className="block text-red-600">Start by creating your first, free API key.</span>
                    </h2>
                    <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                        <div className="inline-flex rounded-md shadow">
                            <a
                                href="#"
                                onClick={createFirstKey}
                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-5 py-3 text-base font-medium text-white hover:bg-red-700"
                            >
                                Get started
                            </a>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <>
                    <div className="px-4 sm:px-6 lg:px-8">
                        <div className="sm:flex sm:items-center">
                            <div className="sm:flex-auto">
                                <h1 className="text-xl font-semibold text-gray-900 py-10">API Clients</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    You have the following API Clients:
                                </p>
                            </div>
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:w-auto"
                                    onClick={() => OpenCreateForm()}
                                >
                                    Create a new API Client
                                </button>
                            </div>
                        </div>
                        <div className="mt-8 flex flex-col">
                            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                    <div
                                        className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                        <table className="min-w-full divide-y divide-red-300">
                                            <thead className="bg-gray-50">
                                            <tr>
                                                <th scope="col"
                                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                                    Client Name
                                                </th>
                                                <th scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Plan
                                                </th>
                                                <th scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    API Key
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Copy API Key</span>
                                                </th>
                                                <th scope="col"
                                                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Status
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Billing</span>
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Regenerate Key</span>
                                                </th>
                                                <th scope="col" className="py-3.5 pl-3 pr-4 sm:pr-6">
                                                    <span className="sr-only">Delete</span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="divide-y divide-red-200 bg-white">
                                            {keys.map((key) => (
                                                <tr key={key.id} className={isDeleting === key.id ? 'bg-red-100' : ''}>
                                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                        {key.name}
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{key.plan}</td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{key.key}</td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" title={(copyKey.keyId === key.id && copyKey.keyState) ? "Key Copied!!" : "Copy API Key"}
                                                            onClick={(e) => {copyApiKey(key.id, key.key)}}
                                                            className="text-red-600 hover:text-red-900">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path d={(copyKey.keyId === key.id && copyKey.keyState) ? 
                                                                    "m5.5 12.5 4.667 4.5L19.5 8"
                                                                    :
                                                                    "M8 5c-.988 0-1.506.013-1.908.218a2 2 0 0 0-.874.874C5 6.52 5 7.08 5 8.2v9.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874C6.52 21 7.08 21 8.2 21h7.6c1.12 0 1.68 0 2.108-.218a2 2 0 0 0 .874-.874C19 19.48 19 18.92 19 17.8V8.2c0-1.12 0-1.68-.218-2.108a2 2 0 0 0-.874-.874c-.402-.205-.92-.217-1.908-.218M8 5v2h8V5M8 5v-.293A1.707 1.707 0 0 1 9.707 3h4.586A1.707 1.707 0 0 1 16 4.707V5"  
                                                                } />
                                                            </svg>
                                                        </a>
                                                    </td>
                                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{key.status}</td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" title="Edit API Client"
                                                           onClick={() => key.status === 'cancelled' ? "" : openEditForm(key.id, key.name, key.plan) }
                                                           className={key.status === "cancelled" ? "text-gray-600" : "text-red-600 hover:text-red-900" }>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                            </svg>
                                                            <span className="sr-only"> {key.id}</span>
                                                        </a>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" title="Upgrade / Downgrade API Client"
                                                           onClick={() => getSubscriptionBillingURL(key.subscription)}
                                                           className={key.status !== "cancelled" && key.subscription !== "" ? "text-red-600 hover:text-red-900" : "text-gray-600"}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.121 7.629A3 3 0 009.017 9.43c-.023.212-.002.425.028.636l.506 3.541a4.5 4.5 0 01-.43 2.65L9 16.5l1.539-.513a2.25 2.25 0 011.422 0l.655.218a2.25 2.25 0 001.718-.122L15 15.75M8.25 12H12m9 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>

                                                            <span className="sr-only"> {key.id}</span>
                                                        </a>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" title="Regenerate API Key"
                                                           onClick={() => key.status !== "cancelled" && key.status !== "created" ? regenerateKey(key.id) : ""}
                                                           className={key.status !== "cancelled" && key.status !== "created" ? "text-red-600 hover:text-red-900" : "text-gray-600"}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                 viewBox="0 0 24 24" strokeWidth={1.5}
                                                                 stroke="currentColor"
                                                                 className={isRegenerating === key.id ? 'animate-spin w-6 h-6' : 'w-6 h-6'}>
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                      d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                                            </svg>
                                                            <span className="sr-only"> {key.id}</span>
                                                        </a>
                                                    </td>
                                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                                        <a href="#" title="Delete API client"
                                                           onClick={() => key.status === 'cancelled' || (key.status === 'created' && key.subscription === '') ? deleteKey(key.id, key.name, key.plan) : false}
                                                           className={(key.status === 'cancelled' || (key.status === 'created' && key.subscription === '')) ? "text-red-600 hover:text-red-900" : "text-gray-600"}>
                                                            {isDeleting !== key.id && (
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                     viewBox="0 0 24 24" fill="currentColor"
                                                                     className="w-6 h-6">
                                                                    <path fillRule="evenodd"
                                                                          d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                                                          clipRule="evenodd"/>
                                                                </svg>)}
                                                            {isDeleting === key.id && (
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                     viewBox="0 0 24 24" strokeWidth={1.5}
                                                                     stroke="currentColor"
                                                                     className="animate-spin h-5 w-5 mr-3 w-6 h-6">
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"/>
                                                                </svg>)}
                                                            <span className="sr-only"> {key.id}</span>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-10" initialFocus={saveButtonRef} onClose={setOpen}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 overflow-y-auto">
                                <div
                                    className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel
                                            className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                            <div className="sm:flex sm:items-start">
                                                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                                    <Dialog.Title as="h3"
                                                                  className="text-lg font-medium leading-6 text-gray-900">
                                                        {isEditing ? "Edit API Client" : "Create a new API Client"}
                                                    </Dialog.Title>
                                                    <form className="space-y-8 divide-y divide-gray-200">
                                                        <div className="space-y-8 divide-y divide-gray-200">
                                                            <div className="pt-8">
                                                                <div
                                                                    className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                                                    <div className="sm:col-span-6">
                                                                        <label htmlFor="name"
                                                                               className="block text-sm font-medium text-gray-700">
                                                                            Client Name (this is mostly for your
                                                                            reference)
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <input
                                                                                type="text"
                                                                                name="name"
                                                                                id="name"
                                                                                autoComplete="name"
                                                                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                                value={keyName}
                                                                                onChange={(e) => setKeyName(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="sm:col-span-6">
                                                                        <label htmlFor="name"
                                                                               className="block text-sm font-medium text-gray-700">
                                                                            Choose a Plan (Please visit the billing page to upgrade or downgrade)
                                                                        </label>
                                                                        <div className="mt-1">
                                                                            <select
                                                                            id="plan"
                                                                            name="plan"
                                                                            autoComplete="country-name"
                                                                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                                            defaultValue={keyPlan}
                                                                            disabled={isEditing ? true : false}
                                                                            onChange={(e) => setKeyPlan(e.target.value)}
                                                                            >
                                                                                {plans.map(plan => (
                                                                                    <option key={plan.name} value={`${plan.name}`}

                                                                                    >
                                                                                        {plan.name} - {plan.description}
                                                                                    </option>
                                                                                ))}
                                                                                {isEditing ?
                                                                                    <option  value="1x">1x - $0.00 / month & 1 request / second</option> : ""}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>



                                                        <div className="pt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                            <button
                                                                type="button"
                                                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                                                onClick={(e) => {
                                                                    if (saveButtonLabel === 'Create') {
                                                                        createKey(e)
                                                                    } else {
                                                                        updateKey(e, keyId, keyName)
                                                                    }
                                                                }}
                                                                ref={saveButtonRef}
                                                            >
                                                                {loadingAfterClick && (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                         viewBox="0 0 24 24" strokeWidth={1.5}
                                                                         stroke="currentColor"
                                                                         className="animate-spin h-5 w-5 mr-3 w-6 h-6">
                                                                        <path strokeLinecap="round"
                                                                              strokeLinejoin="round"
                                                                              d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"/>
                                                                    </svg>
                                                                )}
                                                                {saveButtonLabel}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                                                onClick={() => setOpen(false) && setLoadingAfterClick(false)}
                                                                ref={cancelButtonRef}
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>

                    <footer>
                        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
                            <div className="flex justify-center space-x-6 md:order-2">
                            <div className="mt-8 md:order-1 md:mt-0">
                                <p className="text-center text-base text-gray-400">&copy;7x by Mamluk. &reg; All rights reserved.</p>
                            </div>
                            </div>
                        </div>
                    </footer>

                </>

            )
        }
    }
}

export default Keys;